
import dimensions from './dimensions';
import prevpage from './prevpage';
import getBrowserVersion from './browser-version';

// 是否在控制台打印信息
const CONSOLE = false;
const reservedParameterNames = {
  value: 'cdvalue',
  language: 'cdlanguage'
};
const PAGEVIEW_EVENT_NAME = 'pt_page_view';
const evnetLabelToParameters = {
  yh: 'uid',
  pf: 'platform',
  pgn: 'pagename',
  type: 'type'
};
const utmParameters = {
  campaignSource: 'utm_source',
  campaignName: 'utm_campaign',
  campaignMedium: 'utm_medium',
  campaignContent: 'utm_content',
  campaignKeyword: 'utm_term'
};

const parameterNameConverter = {
  cdpage: 'pagename',
  screenname: 'pagename',
  pgn: 'pagename',
  dpid: 'spid'
};

const dimensionToParameters = {
  eventAction: 'eventaction'
};
for (var key in dimensions) {
  let parameterName = key.toLocaleLowerCase();
  let dimension = dimensions[key];
  if (reservedParameterNames[parameterName]) {
    parameterName = reservedParameterNames[parameterName];
  }
  if (!dimensionToParameters[dimension]) {
    dimensionToParameters[dimension] = parameterName;
  }
}

function trimParameterValue(value) {
  if (value === 0 || value === false) {
    return value;
  }
  if (value) {
    return (value + '').substr(0, 100);
  } else {
    return '';
  }
}

function replaceParameterName(value) {
  if (value && typeof value === 'string') {
    return value.replace(/\-/g, '_');
  }
  return value;
}

var inFrame = false;
try {
  inFrame = window.top !== window;
} catch (err) {
  inFrame = true;
}

function sendGtag(parameters, path) {
  // 被 iframe 等内嵌的页面不发送埋点
  if (inFrame) return;
  if (typeof window.gtag !== 'function') {
    CONSOLE && console.error('gtag', 'gtag 尚未初始化');
    return;
  }
  if (parameters === 'send') {
    parameters = path;
    path = arguments[2];
  }
  if (typeof parameters !== 'object') {
    parameters = {};
  }

  if (parameters.hitType == 'pageview' && !('PrevPage' in parameters) &&  !('dimension12' in parameters)) {
    parameters.dimension12 = prevpage(parameters.dimension10 || parameters.pagename || parameters.path || parameters.page || '');
  }

  var eventName = parameters.hitType == 'pageview' ? PAGEVIEW_EVENT_NAME : parameters.eventCategory;
  if (!eventName) {
    CONSOLE && console.error('gtag', 'eventName 未定义', { parameters });
    return;
  }
  eventName = replaceParameterName(eventName);
  var eventParameters = {};
  for (var key in parameters) {
    var parameterValue = parameters[key];
    if (!parameterValue) {
      continue;
    }
    switch (key) {
      case 'eventLabel':
        if (typeof parameterValue === 'string') {
          var labels = parameterValue.split('|');
          labels.forEach((label) => {
            if (label) {
              var i = label.indexOf(':');
              if (i > 0) {
                var labelKey = label.substr(0, i);
                var labelValue = label.substr(i + 1);
                if (evnetLabelToParameters[labelKey]) {
                  labelKey = evnetLabelToParameters[labelKey];
                }
                labelKey = parameterNameConverter[labelKey] || labelKey;
                labelKey = replaceParameterName(labelKey);
                eventParameters[labelKey] = trimParameterValue(labelValue);
              }
            }
          });
        }
        break;
      default:
        var parameterName = dimensionToParameters[key];
        if (parameterName) {
          if (reservedParameterNames[parameterName]) {
            parameterName = reservedParameterNames[parameterName];
          }
          parameterName = parameterNameConverter[parameterName] || parameterName;
          parameterName = replaceParameterName(parameterName);
          eventParameters[parameterName] = trimParameterValue(parameterValue);
        }
        break;
    }
  }
  eventParameters['browser_ver'] = getBrowserVersion();
  gtag('event', eventName, eventParameters);
  CONSOLE && console.log('gtag event', { eventName, eventParameters });
}

var page_location = location.href.split('#')[0];
function setGtag(key, value) {
  if (inFrame) return;
  if (typeof window.ga === 'function') {
    window.ga('set', key, value);
  }
  if (typeof window.gtag === 'function') {
    var keyName = utmParameters[key];
    if (keyName) {
      var keyPattern = new RegExp('(\\?|&)' + keyName + '=[^&]+');
      if (keyPattern.test(page_location)) {
        page_location = page_location.replace(keyPattern, '$1' + keyName + '=' + encodeURIComponent(value));
      } else {
        page_location = page_location + ((page_location.indexOf('?') >= 0) ? '&' : '?') + keyName + '=' + encodeURIComponent(value);
      }
      gtag('set', { page_location });
      CONSOLE && console.log('gtag set', { key: 'page_location', value: page_location });
    }
  } else {
    CONSOLE && console.error('gtag', 'gtag 尚未初始化');
    return;
  }
}

function ga4(action, parameters, path) {
  // 被 iframe 等内嵌的页面不发送埋点
  if (inFrame) return;
  CONSOLE && console.log('gtag ' + action, { parameters, path });
  if (typeof window.ga === 'function') {
    window.ga(action, parameters, path);
  }
  switch(action) {
    case 'send':
      sendGtag(parameters, path);
      break;
    case 'event':
      gtag('event', parameters, path);
      CONSOLE && console.log('gtag event', { eventName: parameters, eventParameters: path });
      break;
    case 'set':
      setGtag(parameters, path);
      break;
  }
}
ga4.send = sendGtag;
ga4.set = setGtag;

window.ga4 = ga4;
export default ga4;
