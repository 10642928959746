/*
 * @Author: liming
 * @Date: 2020-03-27 17:36:34
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-08-14 11:35:09
 * 举报及数据举报
 */
import "./index.less";

import { browser as bw } from 'dm-utils-ts';
let browser = bw.browsers();

import { RES_TYPES, RES_TYPENAMES, REASON_TYEPS } from './types';

let $complainBox = null;

// 当前举报数据
let curData = null;

$(() => {
  const getPlatform = () => {
    if(!browser.mobile) {
      return 'pc'
    } else if(browser.ios) {
      return 'ios'
    } else if (browser.android) {
      return 'android';
    }
  }

  const initData = () => {
    $complainBox.find('.complain-box-option').removeClass('active');
  };

  const bindEvents = () => {
    $complainBox.on("click", ".complain-box-option", e => {
      let $target = $(e.target).closest(".complain-box-option");
      $target.toggleClass("active");
      $target.siblings().removeClass("active");
    });

    $complainBox.on("click", ".dm-icon-close", e => {
      $complainBox.hide();
    });

    $complainBox.on("click", "#confirm-complain", e => {
      let $active = $complainBox.find(".complain-box-option.active");
      if (!$active.length) {
        alert("请至少选择一种理由!");
        return;
      }
      curData.resType = RES_TYPES[curData.type];
      curData.reason = $active.data("id");
      curData.resId = curData.resid;
      curData.platform = getPlatform();

      console.log(curData);
      
      $.post(`/www/comment/complaint`, curData, function(res){
        if (res.status == 1) {
          helper.pop(res.msg, 1500, {left: window.innerWidth / 2 - 55, top: window.innerHeight / 2 - 20, position: 'fixed', 'margin-left': 0});
          $complainBox.hide();
        } else {
          alert(res.msg || "举报失败,请稍后重试!");
        }
      }, 'json');

      // dmFly
      //   .post(`/api/communication/v1/complaint`, curData)
      //   .then(function(res) {
      //     if (res.success) {
      //       helper.pop('举报成功!', 1500, {left: window.innerWidth / 2 - 55, top: window.innerHeight / 2 - 20, position: 'fixed', 'margin-left': 0});
      //       $complainBox.hide();
      //     } else {
      //       alert(res.error || "举报失败,请稍后重试!");
      //     }
      //   })
      //   .catch(function(error) {
      //     alert("举报失败,请稍后重试!");
      //   });
    });
  };

  //处理ios点击兼容问题
  $("body a[data-target='show-complain-box']").on("click", () => {});
  $("body").on("click", 'a[data-target="show-complain-box"]', e => {
    //必须先登录
    if (!$.cookie("usertoken")) {
      let referer = window.parent.location.href;
      referer = encodeURIComponent(referer);
      topbar.top.showLoginBox(topbar.login_url, referer, true);
      return false;
    }

    let $target = $(e.target).closest("a");
    let data = $target.data();
    curData = data;
    var typeName = RES_TYPENAMES[data.type] || '';
    if (!$complainBox) {
      $("body").append(`<div id="complain-box" class="complain-box">
        <div class="complain-box-header">举报${typeName}<i class="dm-icon-close"></i></div>
        <div class="complain-box-content">
          <p>请选择举报理由</p>
          <ul>${
            REASON_TYEPS.map((types) => `<li class="complain-box-option" data-id="${types.id}"><i class="dm-icon-danxuan-weixuanzhong-"></i>${types.cn}</li>`).join('')
          }</ul>

          <button id="confirm-complain">确定</button>
        </div>
      </div>`);
      $complainBox = $("#complain-box");
      bindEvents();
    }

    initData();
    $complainBox.show();
  });
});
