function getBrowserVersion(userAgent) {
  if (!userAgent) {
    userAgent = navigator.userAgent;
  }
  try {
    if (userAgent.indexOf("Firefox") !== -1) {
      return (userAgent.match(/Firefox\/([0-9.]+)/)[1]);
    } else if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf("OPR") !== -1) {
      return (userAgent.match(/Opera\/([0-9.]+)/)[1]);
    } else if (userAgent.indexOf("Edge") !== -1) {
      return (userAgent.match(/Edge\/([0-9.]+)/)[1]);
    } else if (userAgent.indexOf("Chrome") !== -1) {
      return (userAgent.match(/Chrome\/([0-9.]+)/)[1]);
    } else if (userAgent.indexOf("CriOS") !== -1) {
      return (userAgent.match(/CriOS\/([0-9.]+)/)[1]);
    } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Version/") !== -1) {
      return (userAgent.match(/Version\/([0-9.]+)/)[1]);
    } else {
      // 无法识别的浏览器，返回-1
      return -1;
    }
  } catch (error) {
    return -1;
  }
}

export default getBrowserVersion;
