/**
 * 公用的组件
 */

require("dm-icon");
require("dm-lazy-call");
require("dm-winpop");
require("dm-make-lazy");
require("dm-jcropper");
require("dm-emojify");
require("dm-go-to-top");
require("dm-select-upload-file");
require("dm-complain-www");
require("dm-ajax-file-upload");
require("dm-less");
require("dm-tips");
require("dm-share");
require("dm-loadmore");
// require("dm-drag-upload");
require("dm-scroll");
require("dm-ga");
require("dm-helper");
require("dm-statistics");
require("dm-big-render");
require("dm-weibo-utils");
require("dm-layer-login");
require("dm-topbar");
require("dm-dialog");
require("dm-sp-hot");
require('dm-impression');
require('./_common/widget/eventPcTip');
import landing from 'dm-landing';

import { url } from "dm-utils-ts";

import DmRightFloat from './_common/common/right-float';
import "./home/site-jump";
import './_common/widget/newsletter-subscribe';

import onLuxmoonClick from '../mob/luxmoon/link-click-pc';
import { replaceExecOnClick } from 'dm-exec-capture/replace-exec';

// 全局lang,x_from_site处理
$.ajaxSetup({
  beforeSend: function(xhr, params) {
    // 发送请求前触发
    if (window.dmLang) {
      params.url = url.replaceUrlParam(params.url, "lang", window.dmLang);
    }
    // 全局处理x_from_site
    let x_from_site = url.getQuery("x_from_site");
    if (x_from_site) {
      params.url = url.replaceUrlParam(params.url, "x_from_site", x_from_site);
    }
    return true;
  }
});

var _hotfix_modules_css = "loaded";

landing();

$(() => {
  setTimeout(() => {
    $(window).trigger("scroll");
  }, 1000);
  onLuxmoonClick();
  replaceExecOnClick();
  // 全局处理x_from_site
  // let x_from_site = url.getQuery("x_from_site");
  // window.x_from_site = x_from_site;
  // if (x_from_site) {
  //   $("a").each((index, node) => {
  //     let href = node.href;
  //     if (/http(s):\/\//.test(href)) {
  //       node.href = url.replaceUrlParam(href, "x_from_site", x_from_site);
  //     }
  //   });
  // }
});

// iframe动态高度变化
window.addEventListener('message', function (event) {
  if (event && event.data && event.data.type == "changeiframeHeight" && /dazhe|dealmoon/.test(event.data.host)) {
    var data = event.data;
    var dom = document.querySelector(data.id);
    if(dom) {
      dom.height = data.height;
    }
  }
}, false);
