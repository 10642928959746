
export const replaceExec = function (a) {
  var targetSite = window.countryCode;
  if (targetSite && a && !a._targetSite_replaced) {
    if (/(\?|&)target_site=[^&]+/.test(a.href)) {
      a.href = a.href.replace(/(\?|&)target_site=[^&]+/, '$1target_site=' + targetSite);
    } else {
      a.href = a.href + (a.href.indexOf('?') > -1 ? '&' : '?') + 'target_site=' + targetSite;
    }
    a._targetSite_replaced = true;
  }
}

export const replaceExecOnClick = function () {
  document.addEventListener('click', function (e) {
    if (e) {
      var a = e.srcElement || e.target;
      if (a && a.tagName != 'A' && typeof a.closest === 'function') {
        a = a.closest('a');
      }
      if (a && a.tagName === 'A' && /\/exec\/j\/?[\?&].+/.test(a.href)) {
        replaceExec(a);
      }
    }
  });
}
