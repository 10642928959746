// 举报对象的类型
export const RES_TYPES = {
  'user-profile': 101,          // 个人主页
  'guide-detail': 201,          // 长文章详情
  'post-detail': 202,           // 笔记详情
  "comment-deal": 301,          // 折扣评论
  "comment-guide": 302,         // 长文章评论
  "comment-post": 303,          // 笔记评论
  "comment-sp": 304,            // 单品评论
  "comment-local_business": 305,// Local 商家评论
  "comment-local": 306,         // Local 折扣评论
  "comment-marketplace": 307,   // 二手评论
  'deal-detail': 401,           // 折扣详情
  'sp-detail': 501,             // 单品详情
  'baoliao-detail': 601,        // 爆料详情
  'marketplace': 701,           // 二手
};
export const RES_TYPENAMES = {
  'user-profile': '用户',       // 个人主页
  'guide-detail': '长文章',          // 长文章详情
  'post-detail': '笔记',           // 笔记详情
  "comment-deal": '评论',          // 折扣评论
  "comment-guide": '评论',         // 长文章评论
  "comment-post": '评论',          // 笔记评论
  "comment-sp": '评论',            // 单品评论
  "comment-local_business": '评论',// Local 商家评论
  "comment-local": '评论',         // Local 折扣评论
  "comment-marketplace": '评论',   // 二手评论
  'deal-detail': '折扣',           // 折扣详情
  'sp-detail': '好价',             // 单品详情
  'baoliao-detail': '爆料',        // 爆料详情
  'marketplace': '二手',           // 二手
};
export const REASON_TYEPS = [
  {
    id: 1,
    cn: '广告',
  },
  {
    id: 2,
    cn: '诈骗',
  },
  {
    id: 3,
    cn: '色情',
  },
  {
    id: 4,
    cn: '人身攻击',
  },
  {
    id: 5,
    cn: '政治',
  },
  {
    id: 6,
    cn: '暴恐',
  },
  {
    id: 7,
    cn: '谣言',
  },
  {
    id: 8,
    cn: '低俗',
  },{
    id: 9,
    cn: '赌博',
  },
  {
    id: 10,
    cn: '侵权',
  },
  {
    id: 99,
    cn: '其他',
  }
];

export const MARKETPLACE_REASON_TYEPS = [
  {
    id: 21,
    cn: '假冒/盗版',
  },
  {
    id: 22,
    cn: "虚假宣传",
  },
  {
    id: 23,
    cn: '违禁物品',
  },
  {
    id: 24,
    cn: '垃圾广告',
  }
];
